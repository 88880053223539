/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { axiosInstance } from "../services/customaxios";
import {
  Box,
  OutlinedInput,
  Button,
  Link,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

import styles from "./Enrollment.module.scss";
import { useGlobalUserData } from "../lib/globalUserData";
import LoaderFis from "../components/Loader/LoaderFIS";
import { useTranslation } from "../contexts/Localization";
import { getMessage } from "../utils/status";
import { mask, emailMask } from "../utils/mask";
import FisAlert from "./FisAlert";
import FisTranslatedAlert from "./FisTranslatedAlert";
import { FISFlow } from "../utils/fetchFIS";
import parseJWT from "../utils/parseJWT";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FISWrapper } from "./FISWrapper";
import { sha256 } from "../utils/sha256";
import { formatDevices } from "../utils/formatDevices";
import MigLoader from "../components/Loader/MigLoader";

const Login = () => {
  const { changeStep, userData, updateUserData, globalAlert, showGlobalAlert } =
    useGlobalUserData();
  const { t, apiLanguage } = useTranslation();

  const [migrationDone, setMigrationDone] = useState(false);
  const [formData, setFormData] = useState({ accountType: "empty" });
  const [formErrors, setFormErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showUserid, setShowUserid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [migLoading, setMigLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const useridRef = useRef();
  const [onLoadLoading, setOnLoadLoading] = useState(false);
  const [localAlert, setLocalAlert] = useState({ type: "", message: "" });
  const userPasswordRef = useRef(null);
  let migrationInfo = {};
  let migstatusRef = useRef(null);
  let tmxDoneRef = useRef(false);
  const [migFailed, setMigFailed] = useState(false);
  const [migMsg, setMigMsg] = useState({
    es: "Quédate conectado mientras actualizamos tu Banca Online.",
    en: "Stay tuned while we update your online banking.",
  });
  const migMessages = [
    {
      es: "Quédate conectado mientras actualizamos tu Banca Online.",
      en: "Stay tuned while we update your online banking.",
    },
    {
      es: "Este proceso debe terminar en menos de un minuto.",
      en: "This process should take less than a minute.",
    },
    {
      es: "Trabajando en los últimos detalles",
      en: "Putting on the finishing touches",
    },
    {
      es: "Terminando pronto",
      en: "Wrapping up soon",
    },
    {
      es: "¡Casi listo!",
      en: "Almost done!",
    },
  ];

  const showHidePasswordHandler = (e) => {
    e.preventDefault();
    setShowPassword(showPassword ? false : true);
  };

  const showHideUseridHandler = (e) => {
    e.preventDefault();
    setShowUserid(showUserid ? false : true);
  };

  const navigateBack = () => {
    // not a code updateUserData({
    //   ...userData,
    //   globalShowPasswordField: false,
    //   userid: '',
    //   useridMaskedValue: ''
    // });
    showGlobalAlert({ type: "", message: "" });
    setLocalAlert({ type: "", message: "" });
    const updateData = {
      ...userData,
      globalShowPasswordField: false,
      userpassword: "",
      password: "",
      passwordMaskedValue: "",
    };
    if (!userData.rememberMe) {
      updateData.userid = "";
      updateData.useridMaskedValue = "";
    }
    updateUserData(updateData);
  };

  const onPasswordChange = (e) => {
    const cursorPos = userPasswordRef.current.selectionEnd;
    const value = e.target.value;
    const unmasked = userData.userpassword;
    // This is going to be the new original value (unmasked)
    const newValue = value.replace(
      new RegExp(
        `${cursorPos ? "(^\\" + "•" + "{1," + cursorPos + "})|" : ""}(\\•+)`,
        "g"
      ),
      (match, part, offset, string) => {
        if (!offset && cursorPos) return unmasked.substr(0, match.length);
        else return unmasked.substr(-match.length);
      }
    );

    // mask value
    const maskedValue = mask(value, 0);
    updateUserData({
      ...userData,
      userpassword: newValue,
      passwordMaskedValue: maskedValue,
    });
  };

  const onFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case "userid":
        const maskedUserid = mask(e.target.value);
        updateUserData({
          ...userData,
          userid: e.target.value,
          useridMaskedValue: maskedUserid,
        });
        if (e.target.value === "") {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "Please Enter User ID",
          });
        } else {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "",
          });
        }
        break;
      case "userpassword":
      default:
        updateUserData({
          ...userData,
          userpassword: e.target.value,
        });
        if (e.target.value === "") {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "Please Enter Password",
          });
        } else {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "",
          });
        }
        break;
    }
  };

  // useEffect(() => {
  //   setLocalAlert({ type: '', message: '' });
  // }, [globalAlert]);

  useEffect(async () => {
    // useridRef.current.focus();
    const userInputField = document.querySelector(`input[name=userid]`);

    userInputField?.focus();
    const preLoadUserId = localStorage.getItem("rememberedUserId") || "";
    const preLoadMaskUserId = mask(preLoadUserId || "");

    // if (step === 'fisloginusername') {
    //   setShowPasswordField(false);
    //   updateUserData({ ...userData, globalShowPasswordField: false,  userid: preLoadUserId });
    // }
    // updateUserData({ ...userData, globalShowPasswordField: false, userid: preLoadUserId });

    // if (preLoadUserId) {
    //   setRememberMe(true);
    // }
    // backup remove later setFormData({
    //   ...formData,
    //   // ...userData,
    //   accountType: 'empty',
    //   userid: preLoadUserId || '',
    // });
    const tmxSessionId = await sha256(crypto.randomUUID());
    sessionStorage.setItem("tmxSessionId", tmxSessionId);
    updateUserData({
      ...userData,
      userid: preLoadUserId,
      globalShowPasswordField: false,
      useridMaskedValue: preLoadMaskUserId,
      rememberMe: preLoadUserId ? true : false,
      userpassword: "",
      passwordMaskedValue: "",
    });
    setLocalAlert({ type: "", message: "" });
    setOnLoadLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/threatmatrix/config`,
        {},
        {
          headers: {
            appid: "idm",
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      ?.then(({ data }) => {
        setOnLoadLoading(false);
        // useridRef.current.focus();
        userInputField?.focus();

        const tmProfilingUrlSpace = JSON.parse(data.result[0])[
          "tmProfilingUrl "
        ];
        const { tmOrgId, tmProfilingUrl } = JSON.parse(data.result[0]);
        const se = document.createElement("script");
        se.type = "text/javascript";
        se.id = "tmxSessionIdentifier";
        se.src = `${
          tmProfilingUrl || tmProfilingUrlSpace
        }?org_id=${tmOrgId}&session_id=${tmxSessionId}`;
        document.getElementsByTagName("head")[0].appendChild(se);
        tmxDoneRef.current = true;
      })
      .catch((err) => {
        setOnLoadLoading(false);
      });

    return () => {
      showGlobalAlert({ type: "", message: "" });
      setLocalAlert({ type: "", message: "" });
      updateUserData({ ...userData, globalShowPasswordField: false });
    };
  }, []);

  const proceedWithoutOTPFlow = () => {
    const passwordPayload = {
      step: "password",
      data: {
        loginName: userData.userid,
        browserFootprint:
          "557ce527b9b389cd30796fc85cb5f8e26f3ef0673cc6737f69cabef5851cbc0e",
        // selectedDeviceId: '689192',
        otpPin: "",
        password: userData.userpassword,
      },
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/password`,
        passwordPayload,
        {
          withCredentials: "include",
          headers: {
            appid: "idm",
            language: apiLanguage,
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      .then((resp) => {
        if (
          resp.data.result[0].nextStep &&
          resp.data.result[0].nextStep.id === "changepassword"
        ) {
          updateUserData({
            ...userData,
            cookie: resp.data.result[0].cookies[0],
          });
          changeStep("migrationchangepassword");
        } else if (
          resp.data.result[0].nextStep &&
          resp.data.result[0].nextStep.id === "policy"
        ) {
          updateUserData({
            ...userData,
            cookie: resp.data.result[0].cookies[0],
            policy: {
              pageTitle: resp.data.result[0].nextStep.params.pageTitle,
              policyText: resp.data.result[0].nextStep.params.policyText,
              signature: resp.data.result[0].nextStep.params.signature,
              date: resp.data.result[0].nextStep.params.date,
            },
          });
          changeStep("fisterms");
        } else {
          if (resp.data.status.code === 200) {
            axiosInstance
              .post(
                `${process.env.REACT_APP_IDM_API}authentication/idptoken`,
                {
                  cookie: resp.data.result[0].cookies[0],
                  replaceToken: true,
                },
                {
                  withCredentials: "include",
                  // crossDomain: true,
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
                    appid: "idm",
                    language: apiLanguage,
                  },
                }
              )
              .then(async (respo) => {
                const { id_token } = respo.data.result[0];
                const accessToken = id_token.substring(
                  0,
                  id_token.lastIndexOf(".")
                );
                axiosInstance
                  .post(
                    `${process.env.REACT_APP_IDM_API}authentication/users/getaccesstoken`,
                    {
                      cookie: resp.data.result[0].cookies[0],
                      accessTokenKey: parseJWT(accessToken)?.accessToken,
                    },
                    {
                      withCredentials: "include",
                      // crossDomain: true,
                      headers: {
                        appid: "idm",
                        language: apiLanguage,
                        Accept: "application/json",
                        "x-api-key":
                          process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
                        "Content-Type": "application/json",
                      },
                    }
                  )
                  .then(async (respn) =>
                    FISFlow(respn, userData, changeStep, showGlobalAlert)
                  )
                  .catch((err) => {
                    setLoading(false);
                    updateUserData({
                      ...userData,
                      password: "",
                      userpassword: "",
                      passwordMaskedValue: "",
                    });
                    showGlobalAlert({
                      type: "error",
                      message: getMessage("defaultCode").body,
                    });
                    changeStep("fislogin");
                  });
              })
              .catch((err) => {
                setLoading(false);
                showGlobalAlert({
                  type: "error",
                  body: getMessage("defaultCode").body,
                });
                updateUserData({
                  ...userData,
                  globalShowPasswordField: false,
                });
              });
          } else {
            setLoading(false);
            updateUserData({
              ...userData,
              globalShowPasswordField: false,
              userpassword: "",
              passwordMaskedValue: "",
              password: "",
            });
            setFormData({ ...formData, userpassword: "" });
            if (
              resp.data.status.code === 302 ||
              resp.data.status.code === 316 ||
              resp.data.status.code === 300
            ) {
              // you can't enter a wrong otp if you did not enter any otp
              showGlobalAlert({
                type: "error",
                message: getMessage("defaultCode").body,
              });
            } else {
              const { body } = getMessage(resp.data.status.code);
              showGlobalAlert({ type: "error", message: body });
            }
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        showGlobalAlert({
          type: "error",
          message: getMessage("defaultCode").body,
        });
        updateUserData({
          ...userData,
          userpassword: "",
          passwordMaskedValue: "",
          password: "",
        });
      });
  };

  const userNameNextSteps = (res) => {
    if (res.data.result[0].nextStep?.id === "otpchoice") {
      setLoading(false);
      const devicesList = res.data.result[0].nextStep.params?.devices;
      if (devicesList && devicesList.length > 0) {
        updateUserData({
          ...userData,
          otpDestinations: devicesList,
          userid: userData.userid,
          password: userData.userpassword,
          userpassword: userData.userpassword,
          otpDeviceList: formatDevices(devicesList),
        });
      } else {
        const updateData = {
          ...userData,
          password: "",
          userpassword: "",
          passwordMaskedValue: "",
          globalShowPasswordField: false,
        };
        if (!userData.rememberMe) {
          updateData.useridMaskedValue = "";
          updateData.userid = "";
        }
        updateUserData(updateData);
        setLoading(false);
        showGlobalAlert({
          type: "error",
          message: getMessage("defaultCode").body,
        });
      }
      changeStep("fisloginotpchoice");
    } else {
      proceedWithoutOTPFlow();
    }
  };
  useEffect(() => {
    if (!onLoadLoading) {
      useridRef.current.focus();
    }
  }, [onLoadLoading]);

  const performMigrationAuth = () => {
    setMigLoading(true);
    let msgIndex = 1;
    const updateMessagesPeriodically = () => {
      setMigMsg(migMessages[msgIndex]);
      if (msgIndex < 4) msgIndex++;
    };
    const msgTimer = setInterval(updateMessagesPeriodically, 7000);

    setLoading(false);
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/user/migrationAuth`,
        {
          loginName: userData.userid,
          password: userData.userpassword,
          cookie: `tmSessionId=${sessionStorage.getItem("tmxSessionId")}`,
        },
        {
          withCredentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            appid: "idm",
            language: apiLanguage,
          },
        }
      )
      .then((res) => {
        clearInterval(msgTimer);
        msgIndex = 1;
        if (
          res.data.status.code === 901 ||
          res.data.status.code === 906 ||
          res.data.status.code === 907
        ) {
          setMigLoading(false);
          showGlobalAlert({
            type: "error",
            message: getMessage(res.data.status.code).body,
          });
          updateUserData({
            ...userData,
            userpassword: "",
            passwordMaskedValue: "",
            globalShowPasswordField: false,
            password: "",
          });
        } else if (res.data.status.code !== 200) {
          setMigFailed(true);
          setMigMsg({
            es: "Nos encontramos con un problema al actualizar tu experiencia de Banca Online. Inténtalo nuevamente.",
            en: "We ran into an issue upgrading your Online Banking experience. We are taking care of it on our end. Please try again later.",
          });
          // showGlobalAlert({
          //   type: 'error',
          //   message: getMessage('defaultCode').body,
          // });
        } else {
          setMigLoading(false);
          clearInterval(msgTimer);
          msgIndex = 1;
          userNameNextSteps(res);
        }
      })
      .catch((err) => {
        clearInterval(msgTimer);
        setMigFailed(true);
        setMigMsg({
          es: "Nos encontramos con un problema al actualizar tu experiencia de Banca Online. Inténtalo nuevamente.",
          en: "We ran into an issue upgrading your Online Banking experience. We are taking care of it on our end. Please try again later.",
        });
        // showGlobalAlert({
        //   type: 'error',
        //   message: getMessage('defaultCode').body,
        // });
        updateUserData({
          ...userData,
          password: "",
          userpassword: "",
          passwordMaskedValue: "",
          globalShowPasswordField: false,
        });
      });
  };

  const performBatchAuth = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/batch/batchAuth`,
        {
          userId: userData.userid,
        },
        {
          withCredentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            appid: "idm",
            language: apiLanguage,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status.code === 111) {
          showGlobalAlert({
            type: "error",
            message: getMessage(111).body,
          });
        } else if (
          res.data.status.code === 906 ||
          res.data.status.code === 907 ||
          res.data.status.code === 202
        ) {
          setMigLoading(false);
          showGlobalAlert({
            type: "error",
            message: getMessage(res.data.status.code).body,
          });
          updateUserData({
            ...userData,
            userpassword: "",
            passwordMaskedValue: "",
            globalShowPasswordField: false,
            password: "",
          });
        } else if (res.data.status.code !== 200) {
          showGlobalAlert({
            type: "error",
            message: getMessage("defaultCode").body,
          });
        } else {
          const { email } = migrationInfo;
          updateUserData({
            ...userData,
            emailId: email,
          });
          showGlobalAlert({ type: "", message: "" });
          // const updateData = {
          //   ...userData,
          //   userpassword: '',
          //   password: '',
          // };
          // if (!userData.rememberMe) {
          //   updateData.userid = '';
          //   updateData.useridMaskedValue = '';
          // }
          // updateUserData(updateData);
          // updateUserData({
          //   ...userData,
          //   password: userData.userpassword,
          // });
          setLocalAlert({
            type: "success",
            message: t(
              "A link to reset your password has been sent to the registered email address %emailId%.",
              { emailId: emailMask(email) }
            ),
          });

          // show alert with masked email
        }
      })
      .catch((err) => {
        setLoading(false);
        showGlobalAlert({
          type: "error",
          message: getMessage("defaultCode").body,
        });
        updateUserData({
          ...userData,
          password: "",
          userpassword: "",
          passwordMaskedValue: "",
        });
      });
  };
  const usernameFullFlow = () => {
    // updateUserData({ ...userData, globalShowPasswordField: true });
    setShowUserid(false);
    setShowPassword(false);
    if (
      userData.userid &&
      userData.userpassword &&
      !Object.values(formErrors).some((value) => value !== "")
    ) {
      usernameFullFlowAfterValidation();
    } else {
      setFormErrors({
        ...formErrors,
        userpassword: userData.userpassword ? "" : "Please Enter Password",
      });
    }
  };

  const userNameCall = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/username`,
        {
          step: "username",
          data: {
            keyStroke: "",
            loginName: userData.userid,
            browserFootprint:
              "83018eafcb84c4887e3acef509c47b6e09f9e877fa4be94978e93a83a73f6d23",
            cookie: `tmSessionId=${sessionStorage.getItem("tmxSessionId")}`,
          },
        },
        {
          withCredentials: "include",
          headers: {
            appid: "idm",
            language: apiLanguage,
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      .then((res) => {
        if (res.data.status.code !== 200) {
          const preLoadUserId = localStorage.getItem("rememberedUserId") || "";
          const preLoadMaskUserId = mask(preLoadUserId || "");
          setLoading(false);
          updateUserData({
            ...userData,
            userid: preLoadUserId,
            useridMaskedValue: preLoadMaskUserId,
            rememberMe: preLoadUserId ? true : false,
            password: "",
            userpassword: "",
            passwordMaskedValue: "",
            globalShowPasswordField: false,
          });
          showGlobalAlert({
            type: "error",
            message: getMessage(res.data.status.code).body,
          });
        } else {
          updateUserData({
            ...userData,
            password: userData.userpassword,
            userpassword: userData.userpassword,
          });
          userNameNextSteps(res);
        }
      })
      .catch((err) => {
        const updateData = {
          ...userData,
          password: "",
          userpassword: "",
          passwordMaskedValue: "",
          globalShowPasswordField: false,
        };
        if (!userData.rememberMe) {
          updateData.userid = "";
          updateData.useridMaskedValue = "";
        }
        updateUserData(updateData);
        setLoading(false);
        showGlobalAlert({
          type: "error",
          message: getMessage("defaultCode").body,
        });
        // changeStep('fislogin');
      });
  };

  const usernameFullFlowAfterValidation = () => {
    setLoading(true);
    if (userData.rememberMe) {
      localStorage.setItem("rememberedUserId", userData.userid);
    } else {
      localStorage.removeItem("rememberedUserId", userData.userid);
    }
    if (migstatusRef.current === "ready") {
      performMigrationAuth();
    } else if (!migrationDone) {
      axiosInstance
        .post(
          `${process.env.REACT_APP_IDM_API}authentication/authenticateUser`,
          { username: userData.userid, password: userData.userpassword },
          {
            headers: {
              appid: "idm",
              language: apiLanguage,
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            },
          }
        )
        .then((auth) => {
          if (auth.data.status.code !== 200) {
            showGlobalAlert({
              type: "error",
              message: getMessage(auth.data.status.code).body,
            });
            setLoading(false);
            const updateData = {
              ...userData,
              password: "",
              userpassword: "",
              passwordMaskedValue: "",
              globalShowPasswordField: false,
            };
            if (!userData.rememberMe) {
              updateData.userid = "";
              updateData.useridMaskedValue = "";
            }
            updateUserData(updateData);
          } else {
            userNameCall();
          }
        })
        .catch((erro) => {
          updateUserData({
            ...userData,
            password: "",
            userpassword: "",
            passwordMaskedValue: "",
            globalShowPasswordField: false,
          });
          setLoading(false);
          showGlobalAlert({
            type: "error",
            message: getMessage("defaultCode").body,
          });
        });
    } else {
      userNameCall();
    }
  };
  const submitUsernameAfterValidation = () => {
    setMigLoading(false);

    if (userData.rememberMe) {
      localStorage.setItem("rememberedUserId", userData.userid);
    } else {
      localStorage.removeItem("rememberedUserId", userData.userid);
    }
    if (sessionStorage.getItem("appId") !== "dummy") {
      setLoading(true);
      axiosInstance
        .post(
          `${process.env.REACT_APP_IDM_API}authentication/migration-status-check`,
          {
            userId: userData.userid,
          },
          {
            headers: {
              appid: "idm",
              language: apiLanguage,
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            },
          }
        )
        // axiosInstance.get('migrationapi.json')
        ?.then(
          ({
            data: { externalID, email, status, migrationType, migrationOver },
          }) => {
            setMigrationDone(migrationOver);
            if (migrationType === "INCREMENTAL" || !migrationType) {
              switch (status.toLowerCase()) {
                case "inprogress":
                case "in progress":
                  setLoading(false);
                  if (!migrationOver) {
                    showGlobalAlert({
                      type: "error",
                      message:
                        "We are updating our systems. For help, contact us at 1.800.981.5554 (toll-free), 787.620.0000 or through the chat at orientalbank.com.",
                    });
                  } else {
                    // post migration: show password screen but skip authenticateUser and call usernamestep
                    setLocalAlert({ type: "", message: "" });
                    showGlobalAlert({ type: "", message: "" });
                    migstatusRef.current = status.toLowerCase();
                    changeStep("fislogin");
                    setLoading(false);
                  }
                  break;
                case "completed":
                case "ready":
                case "enrolled":
                  setLocalAlert({ type: "", message: "" });
                  showGlobalAlert({ type: "", message: "" });
                  migstatusRef.current = status.toLowerCase();
                  updateUserData({
                    ...userData,
                    globalShowPasswordField: true,
                  });
                  changeStep("fisloginpassword");
                  setLoading(false);
                  break;
                case "notready":
                case "not ready":
                case "on ready":
                case "failed":
                case "pending":
                case "hold":
                  // pre migration:
                  if (!migrationOver) {
                    window.open(
                      `${process.env.REACT_APP_FIS_LOGIN_UI_URL}#/Login?user_id=${userData.userid}`,
                      "_self"
                    );
                  } else {
                    // post migration: show password screen but skip authenticateUser and call usernamestep
                    showGlobalAlert({
                      type: "error",
                      message: "User ID / Alias is not recognized",
                    });
                    migstatusRef.current = status.toLowerCase();
                    changeStep("fislogin");
                    setLoading(false);
                  }
                  break;
                default:
                  setLoading(false);
                  showGlobalAlert({
                    type: "error",
                    message: getMessage("defaultCode").body,
                  });
                  break;
              }
            } else if (migrationType === "BATCH") {
              updateUserData({
                ...userData,
                migrationInfo: { externalID, email, status, migrationType },
              });
              migrationInfo = { externalID, email, status, migrationType };
              switch (status.toLowerCase()) {
                case "in progress":
                case "inprogress":
                  setLoading(false);
                  showGlobalAlert({
                    type: "error",
                    message:
                      "We are updating our systems. For help, contact us at 1.800.981.5554 (toll-free), 787.620.0000 or through the chat at orientalbank.com.",
                  });
                  break;
                case "completed":
                  showGlobalAlert({ type: "", message: "" });
                  setLocalAlert({ type: "", message: "" });
                  migstatusRef.current = status.toLowerCase();
                  updateUserData({
                    ...userData,
                    globalShowPasswordField: true,
                  });
                  changeStep("fisloginpassword");
                  setLoading(false);
                  break;
                case "passreset":
                  migstatusRef.current = status.toLowerCase();
                  performBatchAuth();
                  break;
                case "notready":
                case "not ready":
                case "on ready":
                case "failed":
                case "pending":
                case "hold":
                case "ready":
                  // pre migration:
                  if (!migrationOver) {
                    window.open(
                      `${process.env.REACT_APP_FIS_LOGIN_UI_URL}#/Login?user_id=${userData.userid}`,
                      "_self"
                    );
                  } else {
                    // post migration: show password screen but skip authenticateUser and call usernamestep
                    showGlobalAlert({
                      type: "error",
                      message: "User ID / Alias is not recognized",
                    });
                    migstatusRef.current = status.toLowerCase();
                    changeStep("fislogin");
                    setLoading(false);
                  }
                  break;
                default:
                  setLoading(false);
                  showGlobalAlert({
                    type: "error",
                    message: getMessage("defaultCode").body,
                  });
                  break;
              }
            } else {
              setLoading(false);
              showGlobalAlert({
                type: "error",
                message: `A link to reset password has been sent to your registered email ${userData.useridMaskedValue}.`,
              });
            }
          }
        )
        .catch((err) => {
          setLoading(false);
          updateUserData({
            ...userData,
            password: userData.userpassword,
          });
          showGlobalAlert({
            type: "error",
            message: getMessage("defaultCode").body,
          });
        });
    } else {
      updateUserData({
        ...userData,
        globalShowPasswordField: true,
      });
      changeStep("fisloginpassword");
      setLoading(false);
      setLocalAlert({ type: "", message: "" });
      showGlobalAlert({ type: "", message: "" });
    }
  };

  const submitUsername = () => {
    setShowUserid(false);
    if (userData.userid && !formErrors.userid) {
      submitUsernameAfterValidation();
    } else {
      setFormErrors({
        ...formErrors,
        userid: userData.userid ? "" : "Please Enter User ID",
      });
    }
  };

  const rememberToggle = () => {
    // setRememberMe(!rememberMe);
    updateUserData({ ...userData, rememberMe: !userData.rememberMe });
  };

  return (
    <>
      <FISWrapper>
        {
          <div style={{ display: "flex", width: "110%", alignItems: "center" }}>
            {userData.globalShowPasswordField && (
              <div>
                <ArrowBackIcon
                  data-testid="backButtonLogin"
                  name="back-btn"
                  onClick={navigateBack}
                />
              </div>
            )}
            <Box id="login-Text" className={styles.heading}>
              {t("Log in to Oriental Online Banking")}
            </Box>
          </div>
        }
        {/* <Box className={styles.subHeading}>
            Let's start by verifying who you are.
          </Box> */}
        <form
          style={{ width: "100%", marginTop: "20px" }}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
          id={userData.globalShowPasswordField ? "passwordpage" : "useridpage"}
        >
          {globalAlert.message && (
            <FisAlert message={globalAlert.message} type={globalAlert.type} />
          )}
          {localAlert.message && (
            <>
              <Box
                id="resetPswd-link"
                name="resetPswd-link"
                style={{
                  display: "flex",
                  border: "1px solid #FFAA00",
                  color: "#FFAA00",
                  padding: "10px",
                  marginTop: "5px",
                  borderRadius: "3px",
                  letterSpacing: "normal",
                  fontSize: "0.9rem",
                }}
              >
                <InfoOutlinedIcon
                  fontSize="small"
                  style={{ color: "#FFAA00", marginRight: "10px" }}
                />
                {t(
                  "A link to reset your password has been sent to the registered email address %emailId%.",
                  {
                    emailId: emailMask(userData.emailId),
                  }
                )}
              </Box>
            </>
            // <FisTranslatedAlert
            //   message={t(localAlert.message, { emailId: emailMask(userData.emailId) })}
            //   type={globalAlert.type}
            // />
          )}
          <Box className={styles.boxMarginTop}>
            <FormControl
              style={{
                width: "100%",
                backgroundColor:
                  userData.globalShowPasswordField || loading || migLoading
                    ? "#f2edf2"
                    : "",
              }}
              error={formErrors && formErrors.userid}
              variant="outlined"
            >
              <InputLabel htmlFor="component-outlined">
                {t("User ID")}
              </InputLabel>
              <OutlinedInput
                id="userid"
                name="userid"
                inputRef={useridRef}
                // label="User ID"
                error={formErrors && formErrors.userid}
                // autoFocus
                onChange={onFormData}
                placeholder={t("User ID")}
                fullWidth
                disabled={userData.globalShowPasswordField || onLoadLoading}
                onBlur={() => setShowUserid(false)}
                onFocus={() => setShowUserid(true)}
                autoComplete="off"
                inputProps={{ maxLength: 50, autoFocus: true }}
                value={
                  showUserid ? userData.userid : userData.useridMaskedValue
                }
                className={styles.passwordInput}
                endAdornment={
                  <InputAdornment
                    position="end"
                    data-testid="showuserIdButton"
                    name="userIdIcon"
                    style={{
                      marginLeft: "0",
                      marginRight: "-7px",
                      maxWidth: "35px",
                    }}
                    tabIndex="0"
                    onBlur={() => setShowUserid(false)}
                  >
                    <IconButton
                      data-testid="showHideUsername"
                      name="usernameIcon"
                      onClick={showHideUseridHandler}
                      tabIndex="0"
                    >
                      {showUserid === true ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {formErrors.userid && (
              <Box className={styles.inlineAlert} id="UserId-err">
                <InfoOutlinedIcon fontSize="small" />
                {t(formErrors.userid)}
              </Box>
            )}
          </Box>
          {userData.globalShowPasswordField && (
            <Box className={styles.boxMarginTop}>
              <FormControl
                style={{ width: "100%" }}
                error={formErrors && formErrors.userpassword}
                variant="outlined"
                // onBlur={() => {
                //   setShowPassword(false);
                //   console.log('hjkhjkhhhuyyiyuy');
                // }}
              >
                <InputLabel htmlFor="component-outlined">
                  {t("Password")}
                </InputLabel>
                <OutlinedInput
                  id="userpassword"
                  name="userpassword"
                  ref={userPasswordRef}
                  onChange={onPasswordChange}
                  placeholder={t("Password")}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  autoComplete="off"
                  type={"text"}
                  value={
                    showPassword
                      ? userData.userpassword
                      : userData.passwordMaskedValue
                  }
                  style={{ borderRight: "none" }}
                  className={styles.passwordInput}
                  // onBlur={() => {
                  //   setPwdBlurFromIcon(false);
                  //   setShowPassword(false);
                  // }}
                  // onFocus={onPwdFocus}
                  // focussedIntoPwd
                  // onFocus={() =>
                  //   pwdBlurFromIcon
                  //     ? setShowPassword(showPassword ? false : true)
                  //     : setShowPassword(false)
                  // }
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{
                        marginLeft: "0",
                        marginRight: "-7px",
                        maxWidth: "35px",
                      }}
                      tabIndex="0"
                      // onBlur={pwdIconBlur}
                    >
                      <IconButton
                        onClick={showHidePasswordHandler}
                        tabIndex="0"
                        data-testid="showHidePasswordHandler"
                        name="pswdIcon"
                      >
                        {showPassword === true ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {formErrors.userpassword && (
                <Box className={styles.inlineAlert} id="pswd_err">
                  <InfoOutlinedIcon fontSize="small" />
                  {t(formErrors.userpassword)}
                </Box>
              )}
            </Box>
          )}
          <Box style={{ marginTop: "10px" }}>
            <input
              type="checkbox"
              id="rememberme"
              data-testid="rememberme"
              name="rememberme"
              onChange={rememberToggle}
              checked={userData.rememberMe}
              className={userData.rememberMe ? "checked" : "unchecked"}
              style={{ transform: "scale(1.5)" }}
            />{" "}
            <label
              for="rememberme"
              id="remembermeText"
              name="remembermeText"
              style={{ fontSize: "16px" }}
            >
              {t("Remember Me")}
            </label>
          </Box>
          <Box className={styles.btn_wrapper}>
            {userData.globalShowPasswordField ? (
              <Button
                variant="text"
                type="submit"
                id="loginBtn"
                data-testid="login-btn"
                name="login-btn"
                className={styles.login_button}
                onClick={usernameFullFlow}
              >
                <LockOutlinedIcon fontSize="small" />
                {t("LOG IN")}
              </Button>
            ) : (
              <Button
                variant="text"
                type="submit"
                id="nextBtn"
                name="next-btn"
                className={styles.login_button}
                onClick={submitUsername}
              >
                <LockOutlinedIcon fontSize="small" />
                {t("Next")}
              </Button>
            )}
          </Box>
        </form>
        <Box className={styles.link_wrapper}>
          <Link
            component="button"
            variant="body2"
            id="troubleSignin"
            color="primary"
            onClick={() => {
              changeStep("fistroublesignin");
            }}
            style={{ textDecoration: "underline" }}
          >
            {t("Trouble signing in?")}
          </Link>
          <Link
            component="button"
            id="enrollment"
            variant="body2"
            color="primary"
            onClick={() => {
              changeStep("enrollment");
            }}
            style={{ textDecoration: "underline" }}
          >
            {t("Not registered? Sign up here.")}
          </Link>
        </Box>
      </FISWrapper>
      <LoaderFis open={loading} />
      <MigLoader
        open={migLoading}
        messages={migMsg}
        migFailed={migFailed}
        setOpen={setMigLoading}
        setMigMsg={setMigMsg}
        setMigFailed={setMigFailed}
      />
    </>
  );
};

export default Login;
