import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";

import { topHeaderStyles } from "./TopHeaderStyles";
import OrientalImage from "../../images/oriental-bank.png";
import { useGlobalUserData } from "../../lib/globalUserData";
import FDICImg from "../../images/FDIC_Header_Logo.svg";

const useStyles = topHeaderStyles();

export default function TopHeader() {
  const styledClasses = useStyles();
  const {
    changeStep,
    step,
    logoutClick,
    setLogoutClick,
    setLogoutbtn,
    logoutbtn,
  } = useGlobalUserData();

  const handleChangeLogout = () => {
    changeStep("fislogin");
    setLogoutbtn(false);
  };

  const goHome = () => {
    changeStep("fislogin");
    setLogoutbtn(false);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {logoutbtn && (
        <Grid
          style={{
            display: "flex",
            position: "absolute",
            left: "85%",
            top: "29%",
          }}
        >
          <button
            id="logoutButton"
            name="logoutBtn"
            onClick={handleChangeLogout}
            style={{
              height: "35px",
              width: "100px",
              background: "#661c69",
              fontSize: "18px",
              color: "#ffffff",
              border: "none",
              borderRadius: "25px",
              cursor: "pointer",
            }}
          >
            Logout
          </button>
        </Grid>
      )}
      <Grid
        container
        className={styledClasses.top_header}
        style={
          step === "demopage"
            ? {
                position: "fixed",
                zIndex: "11111",
                borderBottom: "6px solid #E37617",
              }
            : {}
        }
      >
        <img
          src={OrientalImage}
          className={styledClasses.oriental}
          tabIndex="0"
          id="Oriental-Logo"
          alt="Oriental-Logo"
          aria-label="Oriental Logo"
          onClick={goHome}
          style={{ cursor: "pointer" }}
        />
        <div className={styledClasses.right_mobile_header} />
      </Grid>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "6px",
          padding: "10px",
          alignItems: "flex-start",
        }}
      >
        <Box
          component="img"
          src={FDICImg}
          sx={{ width: "37.36px", height: "15.74px" }}
        />
        <Typography className={styledClasses.fdic_sub_text_style}>
          FDIC-Insured - Backed by the faith and credit of the U.S. Government
        </Typography>
      </div>
    </div>
  );
}
