import React from "react";
import { Grid, Select, MenuItem, Box } from "@material-ui/core";

import { useTranslation } from "../../contexts/Localization";
import FooterImg from "../../images/ehl_Footer.svg";
import FooterMemImg from "../../images/member_fdic.svg";

// const GlobeImg = () => {
//   return (
//     <img style={{ width: '1.5rem' }} src='https://consumer.orientalbank-sit.online-banking-services.com/styles-consumer-sb/b455/images/globe.svg' />

//   )
// }

const Footer = () => {
  const { currentLanguage, setLanguage, t } = useTranslation();
  // may be needed later const [languageString, setLanguageString] = useState('ENG');
  // also may be needed later {/* {t(' %thisYear% orientalbank.com. All Rights Reserved. FDIC Member.', { thisYear: `${new Date().getFullYear()}` })} */}
  const codeInfo = {
    Español: { locale: "es-ES", language: "Español", code: "es-ES" },
    English: { locale: "en-US", language: "English", code: "en" },
  };

  const sendLanguage = (e) => {
    setLanguage(codeInfo[e.target.value]);
  };

  return (
    <footer style={{ backgroundColor: "#fff" }}>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Box
              component="img"
              src={FooterImg}
              style={{
                height: "40px",
                width: "auto",
                filter: "brightness(0) saturate(100%)",
              }}
            />
            <Box
              component="img"
              src={FooterMemImg}
              style={{
                height: "40px",
                width: "auto",
                filter: "brightness(0) saturate(100%)",
              }}
            />
          </div>
          <div id="orientalText" name="oriental-Text">
            &copy; Oriental Bank
          </div>
          <div>
            <a
              id="privacyPolicy"
              target="_blank"
              href={
                currentLanguage.code === "en"
                  ? "https://orientalbank.com/en/privacy-policy"
                  : "https://orientalbank.com/es/politica-de-privacidad/"
              }
            >
              {t("Privacy Policy")}
            </a>
          </div>
        </div>
        <div style={{ display: "flex", height: "26px" }}>
          <img
            style={{ width: "1.5rem" }}
            src="https://consumer.orientalbank-sit.online-banking-services.com/styles-consumer-sb/b455/images/globe.svg"
          />
          <Select
            data-testid="languageSelect"
            role="listbox"
            fullWidth
            inputProps={{ id: "language" }}
            onChange={sendLanguage}
            value={currentLanguage.code === "en" ? "English" : "Español"}
          >
            {["English", "Español"].map((lang) => (
              <MenuItem
                style={{ textDecoration: "underline" }}
                role="option"
                key={lang}
                value={lang}
                id={lang}
                data-testid={lang}
              >
                {lang}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Grid>
    </footer>
  );
};

export default Footer;
